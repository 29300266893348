import { useEffect } from 'react'
import { useAppDispatch, useAppSelector, shallowEqual } from 'hooks/reduxHooks'
import { getTourV2Items } from 'checkout/selectors/view/toursv2'
import { unique } from 'lib/array/arrayUtils'
import { fetchTourV2Offer } from 'actions/TourV2Actions'

export default function useCheckoutTourV2Data(stateOverride?: App.State) {
  const dispatch = useAppDispatch()

  const tourV2Offers = useAppSelector((currentState) => {
    const state = stateOverride ?? currentState
    return getTourV2Items(state)
  }, shallowEqual)

  // Get TourV2 offer data
  useEffect(() => {
    const tourV2OfferIds = unique<string>(tourV2Offers.map(item => item.offerId))
    tourV2OfferIds.forEach((offerId) => dispatch(fetchTourV2Offer(offerId)))
  }, [dispatch, tourV2Offers])
}
