import { API_CALL } from './actionConstants'
import {
  FETCH_TAILOR_MADE_TOUR,
  FETCH_TAILOR_MADE_TOUR_ORDER,
  FETCH_TOUR_OPERATORS,
  FETCH_TOURV2_OFFER,
  FETCH_TOURV2_SNAPSHOT_DETAILS, SEND_WAITLIST_REQUEST,
} from './apiActionConstants'
import { getSnapshotDetailsForTourV2Offer, getTourOperators, requestGetTailorMadeTour, requestGetTailorMadeTourOrder, sendWaitingListRequestForTourV2 } from 'api/tourV2'
import * as OfferService from 'api/offer'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import { AppAction } from './ActionTypes'

export function fetchTourV2Offer(offerId: string, privateRequestKey?: string, regionCode?: string): AppAction {
  return (dispatch, getState) => {
    const state: App.State = getState()

    const offerKey = privateRequestKey ? `${offerId}-${privateRequestKey}` : offerId

    if (state.offer.offersLoading[offerKey] ||
      state.offer.tourV2Offers[offerKey]) {
      return
    }
    dispatch({
      type: API_CALL,
      api: FETCH_TOURV2_OFFER,
      request: () =>
        OfferService.getOfferById(offerId, {
          region: regionCode || state.geo.currentRegionCode,
          privateRequestKey,
        }),
      offerId: offerKey,
    })
  }
}

export function fetchTourV2SnapshotDetails(reservationId: string): AppAction {
  return (dispatch, getState) => {
    const state: App.State = getState()

    if (state.order.tourV2SnapshotsLoading[reservationId] ||
      state.order.tourV2SnapshotDetails[reservationId]) {
      return
    }
    dispatch({
      type: API_CALL,
      api: FETCH_TOURV2_SNAPSHOT_DETAILS,
      request: () =>
        getSnapshotDetailsForTourV2Offer({ reservationId }),
      reservationId,
    })
  }
}

export function fetchTourOperators(): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    if (state.tour.tourOperators.operators) {
      // already have it or are currently fetching it, don't try again
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_TOUR_OPERATORS,
      request: () => getTourOperators(),
    })
  }
}

export function sendWaitListTourSoldOut(requestWaitingListTourData: App.Tours.WaitingListTourRequest) {
  return {
    type: API_CALL,
    api: SEND_WAITLIST_REQUEST,
    request: () => sendWaitingListRequestForTourV2(requestWaitingListTourData)
      .then(() => {
        showSnackbar(
          'We will keep you notified if a spot on this tour becomes available.',
          'success',
          { heading: 'You’ve joined the waitlist' },
        )
      })
      .catch(() => {
        showSnackbar(
          'Please try again later or contact us for assistance.',
          'critical',
          { heading: 'Oops! Something went wrong' },
        )
      }),
  }
}

export function fetchTailorMadeTour(): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    if (state.tour.tailorMadeTour?.data || state.tour.tailorMadeTour?.fetching) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_TAILOR_MADE_TOUR,
      request: () => {
        return requestGetTailorMadeTour({ region: state.geo.currentRegionCode })
      },
    })
  }
}

export function fetchTailorMadeTourOrder(tailorMadeTourRequestId: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const order = state.tour.tailorMadeTourOrders[tailorMadeTourRequestId]

    if (order?.data || order?.fetching) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_TAILOR_MADE_TOUR_ORDER,
      tailorMadeTourRequestId,
      request: () => {
        return requestGetTailorMadeTourOrder(tailorMadeTourRequestId, { region: state.geo.currentRegionCode })
      },
    })
  }
}
